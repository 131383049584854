<template>
    <HeaderView />
    <div>
      <!-- Display user details here -->
      <h2>{{ user.name }}</h2>
      <!-- Add user details as needed -->
  
      <!-- Tabs for displaying orders and wallet information -->
      <div>
  <router-link :to="'orders/' + user._id">Orders</router-link>
  <router-link :to="'wallet/' + user._id">Wallet</router-link>
</div>
  
      <!-- Router view for displaying orders and wallet information -->
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/HeaderView.vue'
  import feathersClient from '@/feathers';
  import { mapGetters } from 'vuex'; // Import mapGetters from Vuex
  export default {
    computed: {
    ...mapGetters(['user', 'accessToken']), // Map the 'user' getter from Vuex
  },
    data() {
      return {
        user: {}, // Store user details here
      };
    },
    components: { HeaderView }
    ,
    created() {
      // Fetch user details here using the user ID from route parameters
      // You can use a method to make an API call to retrieve the user's data
      const userId = this.$route.params.id; // Get user ID from route parameters
      this.fetchUserData(userId);
    },
    methods: {
      async fetchUserData(userId) {
        try {
        const accessToken = this.accessToken
        const response = await feathersClient.service('users').get(userId,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        );
        // Do something with the fetched orders, such as saving them in the component's data or store
        console.log('user:', response)
        this.user = response
      } catch (error) {
        console.log('Error fetching users:', error);
      }
        // Make an API call to fetch user details based on the user ID
        // Update the user data in this.user
      },
    },
  };
  </script>
  