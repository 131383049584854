<template>
  <div>
    <div v-if="switchLayout">
      <n-button @click="switchLayout=false" tertiary circle type="primary">
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
        </template>
      </n-button>
      <h2>{{ activeUser.name }}</h2>
      <div>
        <n-tabs type="segment">
          <n-tab-pane name="Orders" tab="Orders">
            <div v-if="orders?.data?.length > 0">
            <h1>Orders for {{ activeUser?._id }}</h1>
            <div v-for="order in orders.data" :key="order._id" class="order-item">
              <div class="order-row">
                <div class="order-image">
                  <img :src="order.product.thumbnail" alt="Product Image" />
                </div>
                <div class="order-details">
                  <h3>{{ order.product.name }}</h3>
                  <h3>{{ order.orderStatus }}</h3>
                </div>
              </div>

              <div class="order-row">
                <div class="order-info">
                  <p>Customer Price {{ order.businessPrice }}</p>
                  <p>Business Price: {{ order.totalPrice }}</p>
                  <p>Business Commission {{ order.totalBusinessCommission }}</p>
                </div>
                <div class="customer-info">
                  <p>Customer: {{ order.customerName }}</p>
                  <p>Phone: {{ order.customerPhone }}</p>
                  <p>Address1: {{ order.address.hotel }}</p>
                  <p>Address2: {{ order.address.room }}</p>
                </div>
              </div>
            </div>
            </div>
            <div v-else>
              <h1>No Data Found</h1>
            </div>
          </n-tab-pane>
          <n-tab-pane name="Wallet" tab="Wallet">
            <table>
              <thead>
              <tr>
                <th>ID</th>
                <th>User ID</th>
                <th>In Progress</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Available</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in filteredWalletData" :key="item._id">
                <td>{{ item._id }}</td>
                <td>{{ item.userId }}</td>
                <td>{{ item.inprogress }}</td>
                <td>{{ item.createdAt }}</td>
                <td>{{ item.updatedAt }}</td>
                <td>{{ item.available ? item.available : '-' }}</td>
                <td>{{ item.total ? item.total : '-' }}</td>
              </tr>
              </tbody>
            </table>
          </n-tab-pane>
        </n-tabs>
      </div>

    </div>
    <div v-else>
      <h2>{{ users.total }} Users</h2>
      <ul class="order-list">
        <li v-for="user in users.data" :key="user._id" class="order-item">
          <!-- Display user information here -->
          <div class="order-details">
            <div class="order-column">
              <n-button @click="_navigateUser(user)" style="text-decoration: underline" tertiary type="primary">
                {{ user.name }}
              </n-button>
              <h4 class="order-detail">{{ user.phone }}</h4>
              <!-- Add more user details as needed -->
            </div>
            <div class="order-column">
              <h4 class="order-detail">Type: {{ user.type }}</h4>
              <h4 class="order-detail">Business: {{ user.business }}</h4>
              <!-- Add more user details as needed -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</template>


<script>
import feathersClient from '@/feathers';
import { mapGetters } from 'vuex'; // Import mapGetters from Vuex

export default {
  computed: {
    ...mapGetters(['user', 'accessToken']), // Map the 'user' getter from Vuex
    filteredWalletData() {
      if (!this.activeUser || this.activeUser._id === undefined) {
        return [];
      }
      return this.wallet.data.filter(item => item.userId === this.activeUser._id);
    }
  },
  data() {
    return {
      users: [], // Store fetched orders here
      selectedStatus: '', // Selected status from dropdown
      showUpdateButton: false, // Show the update button
      switchLayout: false,
      activeUser: null,
      orders: [],
      wallet: [],
    };
  },
  methods: {
    async fetchUsers() { // Give a name to the method
      try {
        const accessToken = this.accessToken
        const response = await feathersClient.service('users').find({
          query:{
            type:'DesertSafari'
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        );
        // Do something with the fetched orders, such as saving them in the component's data or store
        console.log('users:', response)
        this.users = response
      } catch (error) {
        console.log('Error fetching users:', error);
      }
    },
    handleStatusChange(order) {
      // Show the update button when a status is selected
      console.log('order:', order)
      this.order = order
      this.showUpdateButton = true;
    },
    formatDate(date) {
      let d = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric',
        minute: 'numeric',
        hour12: true };
      return d.toLocaleDateString(undefined, options);
    },
    async fetchOrders(userId) {
      const accessToken = this.accessToken;
      try {
        const response = await feathersClient.service('safariorders').find({
          query: {
            businessId: userId,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.orders = response;
      } catch (error) {
        console.log('error:', error);
      }
    },
    async fetchWallet(userId) {
      const accessToken = this.accessToken;
      try {
        const response = await feathersClient.service('myearnings').find({
          query: {
            businessId: userId,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log("wallet",response)
        this.wallet = response;
      } catch (error) {
        console.log('error:', error);
      }
    },
    _navigateUser(user) {
      this.fetchOrders(user?._id);
      this.fetchWallet(user?._id);
      this.activeUser = user;
      this.switchLayout =true
    },
  },
  mounted() {
    this.fetchUsers(); // Call the method when the component is mounted
  },
};
</script>



<style>
ul.order-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  /* Add margin to create spacing between li elements */
}

.order-item {
  border: 1px solid #ccc;
  margin: 20px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.order-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.order-image img {
  max-width: 100px;
  height: auto;
  margin-right: 10px;
}


li.order-item {
  margin: 10px 0;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  display: flex;
  padding: 10px;
}

.order-details {
  display: flex;
  flex-direction: row;
  /* Display as a row */
  justify-content: space-between;
  /* Space between columns */
  width: 100%;
  /* Take full width */
}

.order-column {
  width: calc(50% - 5px);
  /* Divide width into two columns with spacing */
}

.order-detail {
  margin: 5px 0;
  /* Add margin between h4 elements */
}
.n-tabs .n-tabs-rail .n-tabs-tab-wrapper .n-tabs-tab.n-tabs-tab--active{
  background-color: #333333 !important;
  color: #ffffff !important;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 50px 0;
  font-size: 18px;
  text-align: left;
}

th, td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}
</style>
