import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView.vue';
import OrdersView from '@/views/OrdersView.vue'; // Add .vue extension
import PaymentsView from '@/views/PaymentsView.vue'; // Add .vue extension
import SettingsView from '@/views/SettingsView.vue'; // Add .vue extension
import ListProductsView from '@/views/ListProductsView.vue';
import UsersView from '@/views/UsersView.vue';
import UserDetailView from '@/views/User/UserDetailView.vue'
import UserOrders from '@/views/User/UserOrdersView.vue'
import UserWallet from '@/views/User/UserWalletView.vue'
import store from "@/store";
//import HeaderView from '@/views/HeaderView.vue'

const routes = [
  { path: '/login', component: LoginView },
  {
    path: '/dashboard',
    component: DashboardView,
    children: [
      { path: '/orders', component: OrdersView },
      { path: '/payments', component: PaymentsView },
      { path: '/settings', component: SettingsView },
      { path: '/products', component: ListProductsView },
      { path: '/users', component: UsersView },
    ],
  },
  {
    path: '/user/:id',
    component: UserDetailView,
    children: [
      {
        path: '/user/orders/:id',
        component: UserOrders,
        props:true
      },
      {
        path: '/user/wallet/:id',
        component: UserWallet,
        props:true
      },
    ],
  },
  { path: '/', redirect: '/login' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.state.isAuthenticated) {
    if (to.path !== '/login') {
      next('/login');
    } else {
      next();
    }
  } else {
    if (to.path === '/login') {
      next('/orders');
    } else {
      next();
    }
  }
});


export default router;
