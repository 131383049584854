<template>
    <header class="app-header">
      <!-- Your header content here -->
      <div style="font-size: 30px;font-weight: 600">DuneXpedia</div>
      <p style="margin-left: 2em;">Manage your orders</p>
    </header>
  </template>

  <script>
  export default {
    name: 'HeaderView',
  };
  </script>

  <style scoped>
  .app-header {
    background-color: #333;
    color: white;
    display: flex;align-items: center;
    padding: .4em 1em;

  }
  </style>
