<template>
  <div>
    <h2>Orders</h2>
    <ul class="order-list">
      <li v-for="order in orders" :key="order.id" class="order-item">
        <div class="order-details">
          <div class="order-column">
            <h4 class="order-detail">{{ order.orderId }}</h4>
            <h4 class="order-detail">{{ formatDate(order.createdAt)  }}</h4>
            <h4 class="order-detail">Booked by: {{ order.businessName }}</h4>
          </div>
          <div class="order-column">
            <h4 class="order-detail">{{ order.customerName }}</h4>
            <h4 class="order-detail">{{ order.customerPhone }}</h4>
            <h4 class="order-detail">{{ order.address.hotel }}</h4>
            <h4 class="order-detail">{{ order.address.room }}</h4>
          </div>
          <div class="order-column">
            <h4 class="order-detail">{{ order.productName }}</h4>
            <h4 class="order-detail">Persons {{ order.totalPax }}</h4>
            <h4 class="order-detail">Price {{ order.businessPrice }}</h4>
            <h4 class="order-detail">B2B Price: {{ order.totalPrice }}</h4>
            <h4 class="order-detail">Commission: {{ order.totalBusinessCommission }}</h4>
          </div>
          <div class="order-column">
            <h4 class="order-detail">{{ order.orderStatus }}</h4>
            <select v-model="selectedStatus" @change="handleStatusChange(order)">
              <option value="created">Created</option>
              <option value="confirmed">Confirmed</option>
              <option value="completed">Completed</option>
            </select>
            <button v-if="showUpdateButton" @click="updateOrderStatus(order)">Update</button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style>
ul.order-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  /* Add margin to create spacing between li elements */
}

li.order-item {
  margin: 10px 0;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  display: flex;
  padding: 10px;
}

.order-details {
  display: flex;
  flex-direction: row;
  /* Display as a row */
  justify-content: space-between;
  /* Space between columns */
  width: 100%;
  /* Take full width */
}

.order-column {
  width: calc(50% - 5px);
  /* Divide width into two columns with spacing */
}

.order-detail {
  margin: 5px 0;
  /* Add margin between h4 elements */
}
</style>

<script>
import feathersClient from '@/feathers';
import { mapGetters } from 'vuex'; // Import mapGetters from Vuex

export default {
  computed: {
    ...mapGetters(['user', 'accessToken']), // Map the 'user' getter from Vuex
  },
  data() {
    return {
      orders: [], // Store fetched orders here
      selectedStatus: '', // Selected status from dropdown
      showUpdateButton: false, // Show the update button
    };
  },
  methods: {
    async fetchOrders() { // Give a name to the method
      try {
        const accessToken = this.accessToken
        const response = await feathersClient.service('safariorders').find({
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        );
        // Do something with the fetched orders, such as saving them in the component's data or store
        console.log('orders:', response)
        this.orders = response.data
      } catch (error) {
        console.log('Error fetching orders:', error);
      }
    },
    handleStatusChange(order) {
      // Show the update button when a status is selected
      console.log('order:', order)
      this.order = order
      this.showUpdateButton = true;
    },
    async updateOrderStatus(orderToUpdate) {
      try {
        const accessToken = this.accessToken;
        //const orderToUpdate = this.orders.find(order => order.orderStatus === this.selectedStatus);
        // if (!orderToUpdate) {
        //   console.log('Order not found for update');
        //   return;
        // }
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        // Make a request to update the order status
        const update = await feathersClient.service('safariorders').patch(orderToUpdate._id,
          {
            orderStatus: this.selectedStatus,
          },
          { headers }
        );
        console.log('updated order:', update)

        // Reset the selected status and hide the update button
        this.selectedStatus = '';
        this.showUpdateButton = false;

        // Fetch orders again to reflect the updated status
        await this.fetchOrders();
      } catch (error) {
        console.log('Error updating order status:', error);
      }
    },
    formatDate(date) {
      let d = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric',
        minute: 'numeric',
        hour12: true };
      return d.toLocaleDateString(undefined, options);
    },
  },
  mounted() {
    this.fetchOrders(); // Call the method when the component is mounted
  },
};
</script>
