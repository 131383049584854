<template>
  <div>
    <!-- Display orders data here -->
    <h1>Orders for {{ userId }}</h1>
    <!-- Add orders data rendering here -->
    <div v-for="order in orders.data" :key="order._id" class="order-item">
      <!-- Order details row 1 -->
      <div class="order-row">
        <div class="order-image">
          <img :src="order.product.thumbnail" alt="Product Image" />
        </div>
        <div class="order-details">
          <h3>{{ order.product.name }}</h3>
          <h3>{{ order.orderStatus }}</h3>
          <!-- Add more order details as needed -->
        </div>
      </div>

      <!-- Order details row 2 -->
      <div class="order-row">
        <div class="order-info">
          <p>Customer Price {{ order.businessPrice }}</p>
          <p>Business Price: {{ order.totalPrice }}</p>
          <p>Business Commission {{ order.totalBusinessCommission }}</p>
          <!-- Add more order information as needed -->
        </div>
        <div class="customer-info">
          <p>Customer: {{ order.customerName }}</p>
          <p>Phone: {{ order.customerPhone }}</p>
          <p>Address1: {{ order.address.hotel }}</p>
          <p>Address2: {{ order.address.room }}</p>
          <!-- Add more customer information as needed -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClient from '@/feathers';
import { mapGetters } from 'vuex'; // Import mapGetters from Vuex
export default {
  props: {
    userId: String, // Define the userId prop
  },
  computed: {
    ...mapGetters(['user', 'accessToken']), // Map the 'user' getter from Vuex
  },
  data() {
    return {
      orders: [], // Store user details here
    };
  },
  methods: {
    async fetchOrders(userId) {
      const accessToken = this.accessToken;
      try {
        const response = await feathersClient.service('safariorders').find({
          query: {
            businessId: userId,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.orders = response;
      } catch (error) {
        console.log('error:', error);
      }
    },
  },

  created() {
    const userId = this.$route.params.id;
    this.fetchOrders(userId);
  },
};
</script>

<style scoped>
/* Add CSS styles for the order items and rows */
.order-item {
  border: 1px solid #ccc;
  margin: 20px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.order-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.order-image img {
  max-width: 100px;
  height: auto;
  margin-right: 10px;
}

/* Add more styling as needed for order details, info, and customer info */
</style>
