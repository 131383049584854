<template>
  <div class="login-container">
    <header class="app-header">
      <h1>DuneXpedia</h1>
      <p style="margin-top: -16px">Manage your orders</p>
    </header>
    <h2>Login</h2>
    <n-form  class="login-form">
      <n-input v-model:value="phone" type="text" placeholder="Phone"></n-input>
      <n-input v-model:value="password" type="password" placeholder="Password"></n-input>
        <n-button @click="attemptLogin" type="primary" native-type="submit">Login</n-button>
    </n-form>
  </div>
</template>

<script>
import feathersClient from '@/feathers';
import { mapActions } from 'vuex'; // Import mapActions from Vuex

export default {
    data() {
        return {
            phone: '',
            password: '',
        };
    },
    methods: {
        ...mapActions(['login']),
        async attemptLogin() {
            console.log('going to login');
            console.log("phone",this.phone)
            if (!this.phone){
              alert('Please enter phone')
              return;
            }
            if (!this.password){
              alert('Please enter pass')
              return;
            }
            try {
                let loginResponse = await feathersClient.authenticate({
                    strategy: 'local',
                    phone: this.phone,
                    password: this.password,
                });
                if (loginResponse && loginResponse.accessToken) {
                    console.log('logged in');
                    this.login(loginResponse);
                    this.$router.push('/orders');
                }
                console.log('user:', loginResponse);
                // Redirect to dashboard or fetch user data
            }
            catch (error) {
              alert("Something went wrong")
                console.error('Login failed', error);
            }
        },
    },
};
</script>

<style scoped>
.login-container {
  position: relative;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.app-header {
  text-align: center;
  margin-bottom: 24px;
}


.login-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
