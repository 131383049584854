import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';
import axios from 'axios';

const restClient = rest('https://api.homeworkpulse.com');
const feathersClient = feathers()
  .configure(restClient.axios(axios))
  .configure(auth());

export default feathersClient;