<template>
  <div>
    <div style="width: 100%;display: flex;align-items: center;margin-bottom: 1em;">
    <h2 style="margin-right: 2em">Products</h2>
    <n-button strong secondary type="primary" style="background-color: #333333;color: #fff" round @click="showAddProductModal = true">Add New Product</n-button>
    </div>

    <div class="product-list">
      <div style="position: relative" v-for="product in products" :key="product._id" class="product-card">
        <n-button @click="editProduct(product)" style="position: absolute;top: 10px;right: 50px;background: #333333" tertiary circle type="primary">
          <template #icon>
            <n-icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></n-icon>
          </template>
        </n-button>
        <n-button @click="deleteProduct(product._id)" style="position: absolute;top: 10px;right: 10px;background: #333333" tertiary circle type="primary">
          <template #icon>
            <n-icon>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></n-icon>
          </template>
        </n-button>
        <img style="border-radius: 20px" :src="product.thumbnail" alt="Product Thumbnail" class="product-image" />
        <h3 class="product-name">{{ product.name }}</h3>
        <div class="product-prices">
          <span class="product-price">{{ product.category }},</span>
          <span class="product-price">{{ product.b2bPrice }} B2B Price</span>
          <span class="product-price">{{ product.b2fPrice }} B2F Price</span>
          <span class="product-price">{{ product.retailPrice }} Retail Price</span>
        </div>

        <p class="product-description">{{ product.description }}</p>
        <div class="product-options">
          <h4>Options:</h4>
          <ul>
            <li v-for="option in product.options" :key="option.variant">
              <strong>{{ option?.variant }}:</strong> {{ option?.b2bPrice }} B2B Price, {{option?.b2fPrice}} B2F Price, {{option.retailPrice}} Retail Price
              <div style="display: flex">
                <div v-for="feature in option.Features" :key="feature">{{ feature  }},</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

<!--  modal-->
  <n-modal :on-mask-click="updatePref" style="padding: 2em;background: #fff;" v-model:show="showAddProductModal">
    <div>
      <div style="font-size: 26px;font-weight: 600;margin-bottom: 1em;">{{ isEditing ? 'Update Product' : 'Add New Product' }}</div>
      <div class="add-product-form">
        <n-input style="margin-bottom: 10px" v-model:value="newProduct.name" placeholder="Product Name" />
        <n-input style="margin-bottom: 10px" v-model:value="newProduct.thumbnail" placeholder="Product Thumbnail URL" />
        <n-input style="margin-bottom: 10px" v-model:value="newProduct.category" placeholder="Product Category" />
        <div class="image-section">
          <div style="padding: 1em;background: #f9f9f9;border-radius: 20px;margin-bottom: 1em;">
            <div>Images</div>
            <n-input v-model:value="newImageURL" placeholder="Image URL" />
            <n-button style="margin-top: 10px" @click="addImage">Add Image</n-button>
          </div>

          <div style="display: flex;align-items: center">
            <div style="display: flex;flex-direction: column;align-items: center;padding: 14px" v-for="(image, index) in newProduct.images" :key="index">
              <img style="width: 60px;height: 80px" :src="image" alt="Product Image" class="small-image" />
              <n-button style="margin-top: 8px" @click="removeImage(index)">Remove</n-button>
            </div>
          </div>
        </div>
        <n-input style="margin-bottom: 10px" v-model:value="newProduct.b2bPrice" :value="newProduct?.options[0]?.b2bPrice" placeholder="B2B Price" />
        <n-input style="margin-bottom: 10px" v-model:value="newProduct.b2fPrice" placeholder="B2F Price" />
        <n-input style="margin-bottom: 10px" v-model:value="newProduct.retailPrice" placeholder="Retail Price" />
        <n-input style="margin-bottom: 10px" type="textarea" v-model:value="newProduct.description" placeholder="Description" />
        <div class="option-section">
          <h4>Options</h4>

          <div class="input-group">
            <n-input v-model:value="newOption.variant" placeholder="Variant"></n-input>
            <n-input v-model:value="newOption.b2bPrice" placeholder="B2B Price"></n-input>
          </div>

          <div class="input-group">
            <n-input v-model:value="newOption.b2fPrice" placeholder="B2F Price"></n-input>
            <n-input v-model:value="newOption.retailPrice" placeholder="Retail Price"></n-input>
          </div>

          <div class="input-group">
            <n-input v-model:value="newOption.features" placeholder="Features (comma separated)"></n-input>
          </div>

          <div class="button-group">
            <n-button strong secondary type="primary" style="background-color: #333333;color: #fff" round v-if="isEditingOption" @click="updateOption">Update Option</n-button>
            <n-button strong secondary type="primary" style="background-color: #333333;color: #fff" round v-else @click="addOption">Add Option</n-button>
          </div>

          <div class="options-list">
            <div v-for="(option, index) in newProduct.options" :key="index">
              <div class="option-details">
                <strong>{{ option.variant }}:</strong>
                <div>B2B: {{ option?.b2bPrice }}</div>
                <div>
                  B2F: {{ option?.b2fPrice }}
                </div>
                <div>Retail: {{ option?.retailPrice }}</div>
                <div>Features: {{ option?.features?.join(', ') }}</div>
                <div class="option-actions">
                  <n-button  strong secondary type="primary" style="background-color: #333333;color: #fff" round @click="editOption(index)">Edit</n-button>
                  <n-button strong secondary type="primary" style="background-color: firebrick;color: #fff" round @click="deleteOption(index)">Delete</n-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <n-space style="display: flex;justify-content: center;align-items: center">
        <n-button strong secondary type="primary" style="background-color: firebrick;color: #fff" round @click="closeModal">Cancel</n-button>
        <n-button strong secondary type="primary" style="background-color: #333333;color: #fff" round @click="addProduct">{{ isEditing ? 'Update Product' : 'Add Product' }}</n-button>
      </n-space>
    </div>
  </n-modal>
</template>
<script>
  import feathersClient from '@/feathers';
  import { mapGetters } from 'vuex';

  export default {
  computed: {
    ...mapGetters(['user', 'accessToken']), // Map the 'user' getter from Vuex
  },
  data() {
    return {
      products: [],
      showAddProductModal: false,
      newProduct: {
        name: '',
        thumbnail: '',
        category: '',
        images: [],
        b2bPrice: '',
        b2fPrice: '',
        retailPrice: '',
        description: '',
        options: []
      },
      newProductOption: {
        variant: '',
        price: ''
      },
      isEditing: false,
      newImageURL: '',
      newOption: {
        variant: '',
        b2bPrice: '',
        b2fPrice: '',
        retailPrice: '',
        features: ''
      },
      isEditingOption: false,
      editingIndex: null
    };
  },
  methods:{
    addImage() {
      if (this.newImageURL) {
        if(!this.newImageURL.startsWith("http")){
          alert("Please enter valid image url")
          return;
        }
        this.newProduct.images.push(this.newImageURL);
        this.newImageURL = '';
      }
    },
    removeImage(index) {
      this.newProduct.images.splice(index, 1);
    },
    async fetchProducts() {
      const accessToken = this.accessToken
      const response = await feathersClient.service('safariproducts').find({
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        console.log('products:', response)
        this.products = response.data
    },
    editProduct(product) {
      this.newProduct = { ...product };
      this.showAddProductModal = true;
      this.isEditing = true;

    },
    closeModal() {
      this.showAddProductModal = false;
      this.isEditing = false;
      this.newProduct = {
        name: '',
        thumbnail: '',
        category: '',
        b2bPrice: '',
        b2fPrice: '',
        retailPrice: '',
        description: '',
        options: []
      };
    },
    async updateProduct() {
      try {
        const accessToken = this.accessToken;
        await feathersClient.service('safariproducts').patch(this.newProduct._id, this.newProduct, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.fetchProducts();
        this.showAddProductModal = false;
        this.isEditing = false;
        this.newProduct = {
          name: '',
          thumbnail: '',
          category: '',
          b2bPrice: '',
          b2fPrice: '',
          retailPrice: '',
          description: '',
          options: []
        };
      } catch (error) {
        console.error("Error updating product:", error);
      }
    },
    async addProduct() {
      if (this.isEditing) {
        this.updateProduct();
      } else {
        if (!this.newProduct.name) {
          alert("Product name is required")
          return;
        }
        if (!this.newProduct.thumbnail) {
          alert("Product thumbnail is required")
          return;
        }
        if (!this.newProduct.category) {
          alert("Product category is required")
          return;
        }
        if (!this.newProduct.b2bPrice) {
          alert("Product b2bPrice is required")
          return;
        }
        if (!this.newProduct.b2fPrice) {
          alert("Product b2fPrice is required")
          return;
        }
        if (!this.newProduct.retailPrice) {
          alert("Product retailPrice is required")
          return;
        }
        try {
          const accessToken = this.accessToken;
          await feathersClient.service('safariproducts').create(this.newProduct, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          this.fetchProducts();
          this.showAddProductModal = false;
          this.newProduct = {
            name: '',
            thumbnail: '',
            category: '',
            b2bPrice: '',
            b2fPrice: '',
            retailPrice: '',
            description: '',
            options: []
          };
        } catch (error) {
          console.error("error adding product", error);
        }
      }
    },
    async deleteProduct(productId) {
     if (confirm("Are you sure to delete product?")){
       try {
         const accessToken = this.accessToken;
         await feathersClient.service('safariproducts').remove(productId, {
           headers: {
             Authorization: `Bearer ${accessToken}`,
           },
         });
         this.fetchProducts();
       } catch (error) {
         console.error("error deleting", error);
       }
     }
    },
    updatePref(){
      this.isEditing = false;
      this.newProduct = {
        name: '',
        thumbnail: '',
        category: '',
        b2bPrice: '',
        b2fPrice: '',
        retailPrice: '',
        description: '',
        options: []
      };
    },
    addOption() {
      if (this.newOption.variant && this.newOption.b2bPrice && this.newOption.b2fPrice && this.newOption.retailPrice) {
        this.newOption.features = this.newOption.features?.split(',')?.map(feature => feature?.trim());
        this.newProduct.options?.push({ ...this.newOption });
        this.resetForm();
      } else {
        alert("Please fill all fields");
      }
    },
    editOption(index) {
      const option = this.newProduct.options[index];
      this.newOption = {...option, features: option.features?.join(', ')};
      this.isEditingOption = true;
      this.editingIndex = index;
    },
    updateOption() {
      if (this.editingIndex !== null) {
        this.newOption.features = this.newOption.features?.split(',').map(feature => feature.trim());
        this.newProduct.options[this.editingIndex] = {...this.newOption};
        this.resetForm();
      }
    },
    deleteOption(index) {
      if (confirm("Are you sure you want to delete this option?")) {
        this.newProduct?.options?.splice(index, 1);
        this.isEditingOption = false
      }
    },
    resetForm() {
      this.newOption = {
        variant: '',
        b2bPrice: '',
        b2fPrice: '',
        retailPrice: '',
        features: ''
      };
      this.isEditingOption = false;
      this.editingIndex = null;
    }
  },
  mounted(){
    this.fetchProducts()
  }
}

</script>


<style>
.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.product-card {
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
  background-color: #fff;
  border-radius: 30px;
}
.product-image {
  max-width: 100%;
  height: auto;
}
.product-name {
  margin: 10px 0;
  font-size: 1.2rem;
}
.product-prices {
  font-size: 1rem;
}
.product-price {
  margin-right: 10px;
}
.product-description {
  margin: 10px 0;
}
.product-options {
  margin-top: 10px;
}
.product-options h4 {
  font-size: 1.1rem;
  margin-bottom: 5px;
}
.product-options ul {
  list-style-type: none;
  padding-left: 0;
}
.product-options li {
  margin-bottom: 5px;
}
.product-options li strong {
  font-weight: bold;
}
.option-section {
  padding: 2em;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 1em auto;
}
.input-group {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
}
.button-group {
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}
.options-list {
  margin-top: 2em;
  border-top: 1px solid #eee;
  padding-top: 1em;
}
.option-details {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1em;
  border-radius: 14px;
  position: relative;
}
.option-actions {
  display: flex;
  position: absolute;
  gap: 0.5em;
  right: 20px;
  top: 20px;
}
</style>
