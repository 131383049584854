<template>
  <HeaderView />
  <div class="dashboard">

    <nav class="dashboard-nav">
      <div style="display:flex;flex-direction: column">
        <router-link to="/orders" class="nav-button" active-class="active">Orders</router-link>
        <router-link to="/products" class="nav-button" active-class="active">Products</router-link>
        <router-link to="/payments" class="nav-button" active-class="active">Payments</router-link>
        <router-link to="/settings" class="nav-button" active-class="active">Settings</router-link>
        <router-link to="/users" class="nav-button" active-class="active">Users</router-link>
      </div>

      <n-button style="margin-bottom:10em;" secondary @click="logoutUser">Logout</n-button>
    </nav>
    <div class="dashboard-content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import HeaderView from '@/components/HeaderView.vue'
import {mapActions} from "vuex";
export default {
  methods:{
    ...mapActions(['logout']),
    logoutUser(){
      this.logout();
      this.$router.push("/login"); // Corrected this line
    },
  },
  components: { HeaderView }
}


</script>

<style>
.nav-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px 0;
  text-decoration: none;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 40px;
  transition: background-color 0.3s, color 0.3s;
}

.active {
  background-color: #333;
  color: white;
  border-color: #333;
}

.dashboard {
  display: flex;
}

.dashboard-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px !important;
  padding: 20px;
  height: 100vh;
  background-color: #f0f0f0;
  top: 0;
  position: sticky;
}

.dashboard-content {
  flex-grow: 1; /* Take up remaining space */
  padding: 20px; /* Adjust padding to your preference */

}
</style>
