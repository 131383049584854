import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  plugins: [createPersistedState()],
  state() {
    return {
      isAuthenticated: false,
      accessToken: null,
      user: null,
    };
  },
  mutations: {
    setAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setUser(state, user) {
      state.user = user;
    },
    setAccessToken(state,token){
        state.accessToken = token
    }

  },
  actions: {
    login({ commit }, user) {
      // Perform login logic
      commit('setAuthentication', true);
      commit('setAccessToken', user.accessToken)
      commit('setUser', user.user);
    },
    logout({ commit }) {
      commit('setAuthentication', false);
      commit('setUser', null);
      commit('setAccessToken', null)
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    accessToken: (state) => state.accessToken,
  },
});

export default store;
